
import { defineComponent } from "vue";
import BaseModal from "../modals/BaseModal.vue";

export default defineComponent({
  props: {
    title: String
  },
  components: {
    BaseModal
  },
  setup() {
    return {};
  }
});
