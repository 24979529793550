
import { defineComponent, computed } from "vue";
import queryString from "query-string";
import ResponsiveIframe from "../ResponsiveIframe.vue";
import AsyncIframeWrapper from "./AsyncIframeWrapper.vue";

export default defineComponent({
  props: {
    url: String,
    height: {
      type: Number
    },
    aspectRatio: {
      type: String,
      default: "4:3"
    }
  },
  components: {
    ResponsiveIframe,
    AsyncIframeWrapper
  },
  setup(props) {
    const embedUrl = computed(() => {
      const parsed = queryString.parseUrl(props.url!);
      const { query, url } = parsed;
      const paths = url.split("/");
      const indexOfUrlId = 5;
      const fileId = paths[indexOfUrlId];
      const _embedUrl = `https://drive.google.com/file/d/${fileId}/preview?resourcekey=${query.resourcekey}`;
      return _embedUrl;
    });
    return { embedUrl };
  }
});
