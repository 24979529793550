
// Hampir semua orang mengalami hal yang serupa yaitu 204
// https://stackoverflow.com/questions/40414039/google-docs-viewer-returning-204-responses-no-longer-working-alternatives

// Bisa pake solusi lain menggunakan ms viewer
// https://view.officeapps.live.com/op/embed.aspx?src=[OFFICE_FILE_URL]
// ref: https://stackoverflow.com/questions/40414039/google-docs-viewer-returning-204-responses-no-longer-working-alternatives#comment68101220_40425780

import { defineComponent, ref, nextTick, onMounted } from "vue";
import BaseButton from "@/components/BaseButton.vue";
export default defineComponent({
  props: {
    url: String
  },
  components: {
    BaseButton
  },
  setup() {
    const isOpen = ref(true);
    const isReady = ref(false);
    const isTimeout = ref(false);
    const currentTimeout = ref<any>(null);

    const setIsIframeFullyLoaded = () => {
      isReady.value = true;
    };

    const startTimeout = () => {
      isTimeout.value = false;
      clearTimeout(currentTimeout.value);
      currentTimeout.value = setTimeout(() => {
        isTimeout.value = true;
      }, 7000);
    };

    const reloadIframe = () => {
      isOpen.value = false;
      nextTick(() => {
        startTimeout();
        isOpen.value = true;
      });
    };

    onMounted(() => {
      startTimeout();
    });

    return {
      isReady,
      isOpen,
      isTimeout,
      setIsIframeFullyLoaded,
      reloadIframe
    };
  }
});
