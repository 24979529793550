
import { defineComponent } from "vue";
import BaseModal from "@/components/modals/BaseModal.vue";
import DocViewer from "./DocViewer.vue";

export default defineComponent({
  props: {
    title: String,
    url: String,
    height: {
      type: Number
    },
    aspectRatio: {
      type: String,
      default: "1:1.3"
    }
  },
  components: {
    DocViewer,
    BaseModal
  }
});
