import { createI18n } from "vue-i18n";

export const messages = {
  en: {
    goodMorning: "Good Morning",
    goodAfternoon: "Good Afternoon",
    goodEvening: "Good Evening",
    goodDark: "Good Evening",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    email: "Email",
    password: "Password",
    login: "Login",
    saveChanges: "Save Changes",
    submit: "Submit",

    // Add new keyword here
    searchHere: "Search here...",
    "pagination.listState":
      "Show {fromIndex} to {untilIndex} from {totalAllItems} items.",

    "common.popup.error.title": "Error",
    "common.popup.error.text": "Something went wrong!",
    "common.upload.file.types.hints": "File harus berupa {fileTypes}",
    "aside.menu.home": "Home",
    "aside.menu.transaction": "Transaction",
    "aside.menu.invocation": "Invocation List",
    "aside.menu.bill": "Bill List",
    "aside.menu.payment": "Payment List",
    "aside.menu.webhook": "Webhook List",
    "aside.menu.account.heading": "Account",
    "aside.menu.account": "Account List",
    "aside.menu.mutation": "Mutation List",
    "aside.menu.management": "Client Management",
    "aside.menu.management.client": "Client",
    "aside.menu.management.institution": "Institution",
    "aside.menu.management.account": "Account",
    "aside.menu.management.user": "User",
    "aside.menu.management.gateway": "Gateway",
    "aside.menu.reconcile": "Reconcile",
    "aside.menu.reconcile.channel": "Reconcile File",
    "aside.menu.reconcile.journal": "JE List",
    "aside.menu.reconcile.journal.file": "JE Reconcile File",
    "aside.menu.report": "Reports",
    "aside.menu.report.channel": "Report Channel",
    "aside.menu.setting": "Setting",
    "aside.menu.setting.channel": "Channel",
    "aside.menu.transfer": "Transfer",
    "aside.menu.transfer.history": "Transfer History",

    "bill.breadcrumb": "Bill",
    "bill.title": "Bill List",
    "bill.subtitle": "All bill transactions can be seen on this page",
    "bill.created_at": "Created At",
    "bill.invoice_number": "Invoice Number",
    "bill.va_number": "VA Number",
    "bill.name": "Bill Name",
    "bill.type": "Type",
    "bill.customer": "Customer",
    "bill.status": "Status",
    "bill.total_amount": "Total Amount",
    "bill.billed_amount": "Billed Amount",
    "bill.paid_amount": "Paid Amount",
    "bill.admin_fee": "Admin Fee",
    "bill.component.id": "ID",
    "bill.component.name": "Name",
    "bill.component.price": "Price",
    "bill.component.qty": "Qty",
    "bill.component.total": "Total",

    "invocation.breadcrumb": "Invocation",
    "invocation.title": "Invocation List",
    "invocation.subtitle":
      "All Islamic Pay transactions can be seen on this page",
    "invocation.edit": "Edit Invocation",
    "invocation.created_at": "Created At",
    "invocation.type": "Type",
    "invocation.ref_id": "Channel Ref ID",
    "invocation.reference": "Reference",
    "invocation.institution_name": "Institution Name",
    "invocation.bcn": "BCN",
    "invocation.fqva": "FQVA",
    "invocation.channel": "Channel",
    "invocation.gateway": "Gateway",
    "invocation.amount": "Amount",
    "invocation.status": "Status",
    "invocation.action": "Action",
    "invocation.filterbar.searchbar.submit": "Search",

    "payment.breadcrumb": "Payment",
    "payment.title": "Payment List",
    "payment.subtitle": "List of payment can be seen on this page",
    "payment.id": "Payment ID",
    "payment.reference": "Bill Reference",
    "payment.billId": "Bill ID",
    "payment.paymentRefId": "Payment Ref ID",
    "payment.channel": "Channel",
    "payment.bcn": "BCN",
    "payment.fqva": "FQVA",
    "payment.amount": "Amount",
    "payment.type": "Type",
    "payment.createdAt": "Created At",
    "payment.action": "Action",
    "payment.filterbar.searchbar.submit": "Search",

    "account.title": "Account List",
    "account.subtitle": "The client account that registered on IPG",
    "account.id": "ID",
    "account.accountNumber": "Account Number",
    "account.accountHolder": "Account Holder",
    "account.bankCode": "Bank Code",
    "account.balance": "Balance",
    "account.createdAt": "Created At",
    "account.filterbar.searchbar.submit": "Search",

    "management.client.title": "Client List",
    "management.client.subtitle": "List of client can be seen on this page",
    "management.client.create": "New Client",
    "management.account.title": "Account List",
    "management.account.subtitle": "List of account can be seen on this page",
    "management.account.create": "New Account",
    "management.institution.title": "Institution List",
    "management.institution.subtitle": "List of client can be seen on this page",
    "management.institution.create": "New Institution",
    "management.user.title": "User List",
    "management.user.subtitle": "List of client can be seen on this page",
    "management.user.create": "New User",
    "management.user.edit": "Edit User",
    "management.client.detail.title": "Client Detail",
    "management.gateway.title": "Gateway List",
    "management.gateway.subtitle": "List of gateway can be seen on this page",
    "management.gateway.detail.title": "Gateway Detail",

    "reconcile.channel.breadcrumb": "Reconcile File",
    "reconcile.channel.title": "Reconcile File List",
    "reconcile.channel.subtitle": "List of reconcile channel can be seen on this page",
    "reconcile.channel.time": "Time",
    "reconcile.channel.file": "File Name",
    "reconcile.channel.bcn": "BCN",
    "reconcile.channel.va_number": "Nomor VA",
    "reconcile.channel.total_amount": "Total Bayar",
    "reconcile.channel.date": "Tanggal",
    "reconcile.channel.terminal_id": "Terminal ID",
    "reconcile.channel.ref": "Nomor Ref",
    "reconcile.channel.name": "Nama",
    "reconcile.channel.amount": "Tagihan",
    "reconcile.channel.note": "Keterangan",
    "reconcile.channel.source_account": "Rekening Sumber",
    "reconcile.channel.response_code": "Respon Code",
    "reconcile.channel.fee": "Fee",

    "reports.channel.breadcrumb": "Report",
    "reports.channel.title": "Report List",
    "reports.channel.subtitle":
      "All Islamic Pay report channel & institution can be seen on this page",

    "finance.overbooking.title": "Overbooking",
    "finance.account.inquiry.title": "Add Account",

    "channel.breadcrumb": "Channel History",
    "channel.title": "Channel List",
    "channel.subtitle": "List of channel can be seen on this page",
    "channel.detail.title": "Channel Detail",

    "transfer.breadcrumb": "Transfer History",
    "transfer.title": "Transfer List",
    "transfer.subtitle": "List of transfer can be seen on this page",

    "reconcile.journal.breadcrumb": "Reconcile Journal Entry",
    "reconcile.journal.title": "Reconcile Journal Entry",
    "reconcile.journal.subtitle": "Upload & list journal entry can be seen on this page",

    "reconcile.journal.file.breadcrumb": "Reconcile Journal Entry Files",
    "reconcile.journal.file.title": "Reconcile Journal Entry Files",
    "reconcile.journal.file.subtitle": "List file journal entry can be seen on this page",

    "webhook.breadcrumb": "Webhook Outbound",
    "webhook.title": "Webhook Outbound List",
    "webhook.subtitle": "List of Webhook Outbound can be seen on this page",

    "menu.account_setting": "Setting",
    "menu.signout": "Sign Out",

    "app.info": "App Info",
  }
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages
});

export default i18n;
