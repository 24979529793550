import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createCache from "vuex-cache";
import createPersistedState from "vuex-persistedstate";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import NewBreadcrumbsModule from "@/store/modules/NewBreadcrumbsModule";
import SecureLS from "secure-ls";
import ConfigModule from "./modules/ConfigModule";
import InvocationModule from "./modules/InvocationModule";
config.rawError = true;
const ls = new SecureLS({ isCompression: false });
const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    NewBreadcrumbsModule,
    ConfigModule,
    InvocationModule
  },
  plugins: [
    createPersistedState({
      paths: ["AuthModule.user", "AuthModule.permissions", "AuthModule.roles"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
    createCache(),
  ],
});

export default store;
