import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aspect_ratio_iframe = _resolveComponent("aspect-ratio-iframe")

  return (_openBlock(), _createBlock(_component_aspect_ratio_iframe, {
    url: _ctx.embedUrl,
    height: _ctx.height,
    "aspect-ratio": _ctx.aspectRatio,
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowfullscreen: ""
  }, null, 8, ["url", "height", "aspect-ratio"]))
}