
import { defineComponent, h } from "vue";
import { ElButton, ElTooltip } from "element-plus";

export default defineComponent({
  components: {},
  props: {
    type: String,
    icon: String,
    round: Boolean,
    plain: Boolean,
    circle: Boolean,
    size: String,
    tooltip: String,
    tooltipPlacement: {
      type: String,
      default: "top"
    }
  },
  render() {
    const BaseButton = () => {
      return h(
        ElButton as any,
        {
          ...this.$attrs,
          size: this.size,
          type: this.type,
          icon: this.icon,
          round: this.round,
          plain: this.plain,
          circle: this.circle,
          class: "enhanced-button"
        },
        {
          default: props => this.$slots.default?.(props)
        }
      );
    };

    if (this.tooltip) {
      return h(
        ElTooltip,
        {
          content: this.tooltip,
          placement: this.tooltipPlacement,
          effect: "dark"
        },
        BaseButton
      );
    }
    return BaseButton();
  }
});
