enum Actions {
  // action types
  SHOW_PAGE_PLACEHOLDER = "showPagePlaceholder",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  VERIFY_RESET_PASS = "verifyResetPass",
  CHECK_TOKEN_VALIDITY = "CheckTokenValidity",
  AUTH__GET_COOKIE = "getCookie",
  AUTH__LOGIN = "login",
  AUTH__ME = "me",
  AUTH__LOGOUT = "logout",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  INVOCATION__GET_LIST = "invocation_getList"
}

enum Mutations {
  // mutation types
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_IS_SHOW_PAGE_PLACEHOLDER = "setIsShowPagePlaceholder",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_USER = "setUser",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  BREADCRUMB__SET_PAGE_TITLE = "breadcrumb_setPageTitle",
  BREADCRUMB__SET_BREADCRUMB = "breadcrumb_setBreadcrumb"
}

export { Actions, Mutations };
