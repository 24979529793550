
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import AttachmentPreviewProvider from "@/components/AttachmentPreview/Provider.vue";
import queryString from "query-string";
import { useRouter } from "vue-router";
import AppStyles from "./AppStyles.vue";

const useCypressLogin = (isDevelopment = false) => {
  const store = useStore();
  const isLoggedIn = computed(() => store.getters.isUserAuthenticated);
  const isReady = ref(!isDevelopment);
  const router = useRouter();
  const { query } = queryString.parseUrl(window.location.href);
  onMounted(() => {
    if (query.cypress_login === "true" && !isReady.value && !isLoggedIn.value) {
      store
        .dispatch(Actions.AUTH__LOGIN, {
          school_key: query.school_key,
          username: query.username,
          password: query.password
        })
        .then(() => {
          isReady.value = true;
          router.replace("/");
        });
    } else {
      isReady.value = true;
    }
  });

  return { isReady };
};

export default defineComponent({
  name: "app",
  components: {
    AttachmentPreviewProvider,
    AppStyles
  },
  setup() {
    const store = useStore();

    const { isReady } = useCypressLogin(process.env.NODE_ENV === "development");
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });

    return { isReady };
  }
});
