
import { defineComponent } from "vue";
import ImageViewer from "./ImageViewer.vue";
import SoundPlayer from "./SoundPlayer.vue";
import VideoPlayer from "./VideoPlayer.vue";
import DocViewerModal from "./DocViewerModal.vue";
import AttachmentModal from "./AttachmentModal.vue";
import { useAttachmentPreview, useViewerType } from "./compositions";
import YoutubeViewer from "./YoutubeViewer.vue";
import GDriveViewer from "./GDriveViewer.vue";

export default defineComponent({
  props: {
    fileName: String,
    url: String
  },
  components: {
    DocViewerModal,
    ImageViewer,
    SoundPlayer,
    VideoPlayer,
    YoutubeViewer,
    AttachmentModal,
    GDriveViewer
  },
  setup(props) {
    const { close } = useAttachmentPreview();
    const { viewerType } = useViewerType(props.fileName!, props.url!);
    return { viewerType, close };
  }
});
