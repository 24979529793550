import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body overlay overlay-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_doc_viewer = _resolveComponent("doc-viewer")
  const _component_base_modal = _resolveComponent("base-modal")

  return (_openBlock(), _createBlock(_component_base_modal, {
    title: _ctx.title,
    size: "lg",
    id: "doc-viewer",
    "show-immediately": true,
    scrollable: true,
    onOpen: _ctx.handleOpen
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_doc_viewer, {
          height: _ctx.height,
          "aspect-ratio": _ctx.aspectRatio,
          key: _ctx.url,
          url: _ctx.url
        }, null, 8, ["height", "aspect-ratio", "url"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onOpen"]))
}