import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("iframe", {
    ref: "iframeRef",
    class: "AttachmentPreview-Iframe",
    frameborder: "0",
    style: {
      height: (_ctx.height || _ctx.autoHeight || _ctx.DEFAULT_HEIGHT) + 'px'
    },
    src: _ctx.url
  }, null, 12, ["src"]))
}