
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

export default defineComponent({
  name: "BaseModal",
  props: {
    onOpen: Function,
    onClose: Function,
    scrollable: Boolean,
    size: String,
    id: String,
    title: String,
    showImmediately: Boolean
  },
  components: {},
  setup(props) {
    const showRef = ref<HTMLButtonElement | null>(null);
    const modalRef = ref<HTMLDivElement | null>(null);
    const handleToggleShow = isShowing => {
      if (isShowing && props.onOpen) props.onOpen();
      if (!isShowing && props.onClose) props.onClose();
    };
    const onWrapperClassnameChange = (
      outerCallback: (isShowing: boolean) => void
    ) => mutationList => {
      mutationList
        .filter(mutation => mutation.attributeName == "class")
        .forEach(mutation => {
          const newClassName = mutation.target.className;
          const isShowing = newClassName.includes("show");
          outerCallback(isShowing);
        });
    };
    const observerCallbackHandleToggleShow = onWrapperClassnameChange(
      handleToggleShow
    );
    const observer = new MutationObserver(observerCallbackHandleToggleShow);
    onMounted(() => {
      observer.observe(modalRef.value!, {
        attributes: true
      });
    });
    onBeforeUnmount(() => {
      observer.disconnect();
    });

    const dismissRef = ref<HTMLButtonElement | null>(null);
    const open = () => {
      if (showRef.value) {
        showRef.value!.click();
      }
    };
    const close = () => {
      if (dismissRef.value) {
        dismissRef.value!.click();
      }
    };

    onMounted(() => {
      if (props.showImmediately) {
        open();
      }
    });

    return { dismissRef, open, close, modalRef, showRef };
  }
});
