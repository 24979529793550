import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_responsive_iframe = _resolveComponent("responsive-iframe")
  const _component_async_iframe_wrapper = _resolveComponent("async-iframe-wrapper")

  return (_openBlock(), _createBlock(_component_async_iframe_wrapper, null, {
    default: _withCtx(({ setIsIframeFullyLoaded }) => [
      _createVNode(_component_responsive_iframe, {
        key: _ctx.url,
        url: _ctx.embedUrl,
        allow: "autoplay",
        onReady: setIsIframeFullyLoaded
      }, null, 8, ["url", "onReady"])
    ]),
    _: 1
  }))
}