import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_styles = _resolveComponent("app-styles")
  const _component_router_view = _resolveComponent("router-view")
  const _component_attachment_preview_provider = _resolveComponent("attachment-preview-provider")

  return (_openBlock(), _createBlock(_component_attachment_preview_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_styles),
      (_ctx.isReady)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}