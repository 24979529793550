
import { defineComponent, computed } from "vue";
import ResponsiveIframe from "@/components/ResponsiveIframe.vue";
import AsyncIframeWrapper from "./AsyncIframeWrapper.vue";

export default defineComponent({
  props: {
    url: String
  },
  components: {
    AsyncIframeWrapper,
    ResponsiveIframe
  },
  setup(props) {
    const validUrl = computed(
      () => `https://docs.google.com/viewer?embedded=true&url=${props.url}`
    );

    return {
      validUrl
    };
  }
});
