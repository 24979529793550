import { computed, onMounted, ComputedRef, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "./breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/core/helpers/translate";
import Swal from "sweetalert2";
import { useI18n } from "vue-i18n";
import dotPropImmutable from "dot-prop-immutable";

const isUndefined = a => {
  return typeof a === "undefined";
};

const useBreadCrumb = (pageName: string) => {
  const store = useStore();
  const userType = store.getters.userType;

  onMounted(() => {
    setCurrentPageBreadcrumbs(pageName, [userType]);
  });
};

type RoleName = "Student" | "Teacher" | "Admin";
const useHasRole = () => {
  const store = useStore();
  const userType = computed(() => store.getters.userType);
  const userData = computed(() => store.getters.currentUser);
  const isHasRole = (roleName: RoleName) =>
    userData.value.type.toLowerCase() === roleName.toLowerCase() ||
    userType.value === roleName;
  const isHasSomeRole = (roles: RoleName[]) =>
    roles
      .map(_role => _role.toLowerCase())
      .indexOf(userType.value.toLowerCase()) !== -1;
  const currentRole = computed(() => userType.value);
  const isNotRole = (roleName: RoleName) => userType.value !== roleName;
  return { isHasRole, isNotRole, currentRole, isHasSomeRole };
};

const useQuery = () => {
  const route = useRoute();
  const query = computed<any>(() => route.query);
  return { query };
};

const useParams = () => {
  const route = useRoute();
  const params = computed<any>(() => route.params);
  return { params };
};

const useMutateQuery = (type: "replace" | "push" = "push") => {
  const route = useRoute();
  const router = useRouter();

  const mutateQuery = (newQuery = {}) => {
    router[type]?.({
      query: {
        ...route.query,
        ...newQuery
      }
    });
  };
  return { mutateQuery };
};

const useYupMessage = () => {
  const { t } = useI18n();
  const message = (type: string, label: string, args?: any) => {
    return t(`form.validation.${type}`, { label, ...args });
  };
  const messageT = (type: string, labelTranslated: string, args?: any) => {
    return t(`form.validation.${type}`, { label: t(labelTranslated), ...args });
  };

  return { message, messageT, t };
};

type DeletePopupParams = {
  title?: string;
  text: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  errorMessage?: (err: any) => string;
  action: () => Promise<any>;
  onSuccess?: () => void;
};

const showDeletePopup = (params: DeletePopupParams) => {
  return Swal.fire({
    showClass: {
      popup: "mumtaz-confirm-popup"
    },
    customClass: {
      confirmButton: "mumtaz-confirm-button-popup"
    },
    icon: "warning",
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonColor: "#F56C6C",
    allowOutsideClick: () => !Swal.isLoading(),

    title: params.title || translate("common.popup.confirmation.title"),
    text: params.text,
    cancelButtonText:
      params.cancelButtonText || translate("common.popup.cancelButton"),
    confirmButtonText:
      params.confirmButtonText ||
      translate("common.popup.delete.confirmButton"),
    preConfirm: () =>
      params.action().catch(err => {
        Swal.showValidationMessage(
          params.errorMessage
            ? params.errorMessage(err)
            : `Request failed: ${err}`
        );
      })
  }).then(({ isConfirmed }) => {
    if (isConfirmed && params.onSuccess) {
      params.onSuccess();
    }
  });
};

type SuccessPopupParams = {
  title?: string;
  text: string;
  timer?: number;
};
const showSuccessPopup = (params: SuccessPopupParams) => {
  return Swal.fire({
    showClass: {
      popup: "mumtaz-success-popup"
    },
    icon: "success",
    showConfirmButton: false,
    timer: params.timer || 1500,
    timerProgressBar: true,

    title: params.title || translate("common.popup.success.title"),
    text: params.text
  });
};

type ProgressPopupParams = {
  title?: string;
  text?: string;
  action: () => Promise<any>;
};
const showProgressPopup = (params: ProgressPopupParams) => {
  return Swal.fire({
    showClass: {
      popup: "mumtaz-progress-popup"
    },
    title: params.title || "Memproses",
    text: params.text || "sedang memproses...",
    showConfirmButton: false,
    timer: 100,
    timerProgressBar: true,
    didOpen: async () => {
      Swal.stopTimer();
      await params.action();
      Swal.resumeTimer();
    }
  });
};

type ErrorPopupParams = {
  title?: string;
  text?: string;
  message: string;
};
const showErrorPopup = (params: ErrorPopupParams) => {
  return Swal.fire({
    showClass: {
      popup: "mumtaz-error-popup"
    },
    icon: "error",
    title: params.title || translate("common.popup.error.title"),
    text: params.text || translate("common.popup.error.text"),
    validationMessage: params.message
  });
};

const useSelectTable = (dataTable: ComputedRef<any[]>) => {
  const multipleSelection = ref<any>([]);
  const clearSelection = () => {
    multipleSelection.value = [];
  };
  const multipleSelectionGroupedById = computed(() =>
    multipleSelection.value.reduce(
      (group, item) => ({
        ...group,
        [item.id]: item
      }),
      {}
    )
  );
  const isSelected = row => {
    const item = multipleSelectionGroupedById.value[row.id];
    return !!item;
  };
  const toggleSelection = row => {
    if (isSelected(row)) {
      const selectionIndex = multipleSelection.value.findIndex(
        _row => _row.id === row.id
      );
      multipleSelection.value = dotPropImmutable.delete(
        multipleSelection.value,
        selectionIndex
      );
      return false;
    }
    multipleSelection.value = [...multipleSelection.value, row];
  };
  const isSelectedAll = () => {
    const allRowInPage = dataTable.value as any;
    const selectedList = allRowInPage.filter(isSelected);
    return selectedList.length === allRowInPage.length;
  };
  const toggleSelectAll = () => {
    const _isSelectedAll = isSelectedAll();
    const isRowNeedToBeToggled = row =>
      _isSelectedAll ? isSelected(row) : !isSelected(row);
    const allRowInPage = dataTable.value as any;
    allRowInPage.forEach(row => {
      if (isRowNeedToBeToggled(row)) toggleSelection(row);
    });
  };

  return {
    selectionState: multipleSelection,
    clearSelection,
    isSelectedAll,
    toggleSelectAll,
    isSelected,
    toggleSelection
  };
};

const inputNumbersOnly = (evt) => {
  evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      return true;
    }
};

export {
  useYupMessage,
  isUndefined,
  useHasRole,
  useBreadCrumb,
  useMutateQuery,
  useQuery,
  useParams,
  showDeletePopup,
  showSuccessPopup,
  showProgressPopup,
  showErrorPopup,
  useSelectTable,
  inputNumbersOnly
};
