
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onBeforeUnmount
} from "vue";

export default defineComponent({
  props: {
    url: String,
    height: {
      type: Number
    },
    aspectRatio: {
      type: String,
      default: "4:3"
    },
    onReady: Function
  },
  components: {},
  setup(props) {
    const DEFAULT_HEIGHT = 300;

    const iframeRef = ref<null | HTMLIFrameElement>(null);
    const currentWidth = ref(0);

    const onLoadCallback = () => {
      props.onReady && props.onReady();
    };

    onMounted(() => {
      if (iframeRef.value) {
        setTimeout(() => {
          const clientRects = (iframeRef.value! as HTMLIFrameElement).getClientRects();
          currentWidth.value = clientRects?.[0]?.width;
          iframeRef.value!.addEventListener("load", onLoadCallback);
        }, 500);
      }
    });

    onBeforeUnmount(() => {
      iframeRef.value!.removeEventListener("load", onLoadCallback);
    });

    const parsedRatio = computed(() => {
      const [widthRatioString, heightRatioString] = props.aspectRatio.split(
        ":"
      );
      return {
        widthRatio: parseFloat(widthRatioString),
        heightRatio: parseFloat(heightRatioString)
      };
    });
    const autoHeight = computed(() => {
      return (
        (parsedRatio.value.heightRatio / parsedRatio.value.widthRatio) *
        currentWidth.value
      );
    });
    return { iframeRef, autoHeight, DEFAULT_HEIGHT };
  }
});
