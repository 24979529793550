import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_doc_viewer_modal = _resolveComponent("doc-viewer-modal")
  const _component_image_viewer = _resolveComponent("image-viewer")
  const _component_sound_player = _resolveComponent("sound-player")
  const _component_attachment_modal = _resolveComponent("attachment-modal")
  const _component_video_player = _resolveComponent("video-player")
  const _component_youtube_viewer = _resolveComponent("youtube-viewer")
  const _component_g_drive_viewer = _resolveComponent("g-drive-viewer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.viewerType === 'google_viewer')
      ? (_openBlock(), _createBlock(_component_doc_viewer_modal, {
          title: _ctx.fileName,
          key: _ctx.url,
          url: _ctx.url
        }, null, 8, ["title", "url"]))
      : _createCommentVNode("", true),
    (_ctx.viewerType === 'image')
      ? (_openBlock(), _createBlock(_component_image_viewer, {
          onShouldClose: _ctx.close,
          key: _ctx.url,
          url: _ctx.url
        }, null, 8, ["onShouldClose", "url"]))
      : _createCommentVNode("", true),
    (_ctx.viewerType === 'sound')
      ? (_openBlock(), _createBlock(_component_attachment_modal, {
          key: 2,
          title: _ctx.fileName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sound_player, {
              title: _ctx.fileName,
              key: _ctx.url,
              url: _ctx.url
            }, null, 8, ["title", "url"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.viewerType === 'video')
      ? (_openBlock(), _createBlock(_component_attachment_modal, {
          key: 3,
          title: _ctx.fileName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_video_player, {
              title: _ctx.fileName,
              key: _ctx.url,
              url: _ctx.url
            }, null, 8, ["title", "url"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.viewerType === 'youtube')
      ? (_openBlock(), _createBlock(_component_attachment_modal, {
          key: 4,
          title: _ctx.fileName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_youtube_viewer, { url: _ctx.url }, null, 8, ["url"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.viewerType === 'gdrive')
      ? (_openBlock(), _createBlock(_component_attachment_modal, {
          key: 5,
          title: _ctx.fileName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_g_drive_viewer, { url: _ctx.url }, null, 8, ["url"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}