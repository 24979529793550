import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface Breadcrumb {
  title: string;
  url?: string;
}

interface StoreInfo {
  newBreadcrumbPageTitle: string;
  newBreadcrumbs: Breadcrumb[];
}

@Module
export default class NewBreadcrumbsModule extends VuexModule
  implements StoreInfo {
  newBreadcrumbPageTitle = "" as string;
  newBreadcrumbs = [] as Breadcrumb[];

  get isShowNewBreadcrumbs(): boolean {
    return (this.newBreadcrumbPageTitle!! &&
      this.newBreadcrumbs.length > 0) as boolean;
  }

  get getNewPageTitle(): string {
    return this.newBreadcrumbPageTitle;
  }

  get getNewBreadcrumbs(): Breadcrumb[] {
    return this.newBreadcrumbs;
  }

  @Mutation
  [Mutations.BREADCRUMB__SET_PAGE_TITLE](payload: string) {
    this.newBreadcrumbPageTitle = payload;
  }

  @Mutation
  [Mutations.BREADCRUMB__SET_BREADCRUMB](payload: Breadcrumb[]) {
    this.newBreadcrumbs = payload;
  }
}
