import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import to from "await-to-js";
import { showErrorPopup } from "@/core/helpers/common-helper";

export const downloadToBlobUrl = async (url: string, params?: {} ) => {
  ApiService.setHeader();
  const [err, res] = await to(
    ApiService.vueInstance.axios({
      method: "GET",
      responseType: "blob",
      params: params,
      url
    })
  );
  if (err) {
    showErrorPopup({
      message: err?.toString(),
      title: 'Download Failed',
      text: 'Please try again later'
    });
    return Promise.reject(err);
  }
  const filename = res!.headers['content-disposition'].split('filename=')[1].split('.')[0];
  const extension = res!.headers['content-disposition'].split('.')[1].split(';')[0];
  const blobUrl = URL.createObjectURL(res!.data);
  //return Promise.resolve(blobUrl);
  return {blobUrl, filename, extension};
};

export const useDownloadToBlob = () => {
  const isDownloading = ref(false);
  const handleDownload = (url: string) => {
    isDownloading.value = true;
    return downloadToBlobUrl(url).finally(() => {
      isDownloading.value = false;
    });
  };

  return { handleDownload, isDownloading };
};

const useDownload = () => {
  const isDownloading = ref(false);
  const handleDownload = (url: string) => {
    isDownloading.value = true;
    return downloadToBlobUrl(url)
      .then(response => {
        window.open(response.blobUrl);
      })
      .finally(() => {
        isDownloading.value = false;
      });
  };
  const handleDownloadToFile = (url: string, title: string, params?: any) => {
    isDownloading.value = true;
    return downloadToBlobUrl(url, params)
      .then(response => {
        const link = document.createElement('a')
        link.href = response.blobUrl
        const filename = response.filename + '.' + response.extension;
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        isDownloading.value = false;
      });
  };
  const handleDownloadToFilePDF = (url: string, title: string, params?: any) => {
    isDownloading.value = true;
    return downloadToBlobUrl(url, params)
      .then(response => {
        const link = document.createElement('a')
        link.href = response.blobUrl
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        isDownloading.value = false;
      });
  };

  return { handleDownload, handleDownloadToFile, handleDownloadToFilePDF, isDownloading };
};

export default useDownload;
