
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    url: String
  },
  components: {},
  setup() {
    return {};
  }
});
