import { inject, computed, onBeforeUnmount, ref, Ref } from "vue";

export interface AttachmentData {
  fileName: string;
  url: string;
}

const GOOGLE_VIEWER_FILE_TYPES = [
  "pdf",
  "docx",
  "doc",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "odc",
  "ods",
  "odt",
  "txt",
  "html",
  "js",
  "php"
];
const IMAGE_VIEWER_FILE_TYPES = ["jpg", "jpeg", "png"];
const SOUND_PLAYER_FILE_TYPES = ["mp3", "ogg", "wav"];
const VIDEO_PLAYER_FILE_TYPES = ["mp4", "webm", "mov"];

export const useAttachmentPreview = () => {
  const isLoadingToView = ref(false);
  const latestViewedDocument = ref<null | AttachmentData>(null);
  const baseSetCurrentAttachment = inject("setCurrentAttachment") as (
    attachmentData: AttachmentData
  ) => Promise<boolean>;
  const setCurrentAttachment = (attachmentData: AttachmentData) => {
    isLoadingToView.value = true;
    latestViewedDocument.value = attachmentData;
    return baseSetCurrentAttachment(attachmentData).finally(() => {
      isLoadingToView.value = false;
    });
  };
  const removeCurrentAttachment = inject(
    "removeCurrentAttachment"
  ) as () => void;

  const currentShowingAttachmentData = inject(
    "currentAttachment"
  ) as Ref<AttachmentData | null>;

  // make auto close when the caller component is unmounted
  onBeforeUnmount(() => {
    const isShowingDocument =
      latestViewedDocument.value && currentShowingAttachmentData.value;

    const currentDocumentIsNotClosedYet =
      isShowingDocument &&
      latestViewedDocument.value?.fileName ===
        currentShowingAttachmentData.value?.fileName &&
      latestViewedDocument.value?.url ===
        currentShowingAttachmentData.value?.url;

    if (currentDocumentIsNotClosedYet) {
      removeCurrentAttachment();
    }
  });

  return {
    preview: setCurrentAttachment,
    close: removeCurrentAttachment,
    isLoadingToView
  };
};

const ALL_VIEWER_TYPES = {
  google_viewer: GOOGLE_VIEWER_FILE_TYPES,
  image: IMAGE_VIEWER_FILE_TYPES,
  sound: SOUND_PLAYER_FILE_TYPES,
  video: VIDEO_PLAYER_FILE_TYPES
};

const ALL_URL_TYPES = {
  youtube: "youtube",
  gdrive: "drive.google.com"
};

export const getViewerType = (fileName: string, fileUrl: string) => {
  const getExtension = () => {
    if (!fileName) return null;
    const lastDotIndex = fileName.lastIndexOf(".");
    const actualDotIndex = lastDotIndex + 1;
    const extension = fileName.substr(actualDotIndex, actualDotIndex);
    return extension;
  };
  const extension = getExtension();

  const getUrlType = () => {
    if (!fileUrl) return null;
    let currentUrlType: string | null = null;
    Object.keys(ALL_URL_TYPES).map(urlType => {
      const keywordUrl = ALL_URL_TYPES[urlType];
      const isFound = fileUrl.indexOf(keywordUrl) !== -1;
      if (isFound) {
        currentUrlType = urlType;
      }
    });
    return currentUrlType;
  };
  const urlType = getUrlType();

  const getViewerType = () => {
    let currentViewer: string | null = null;
    if (urlType) {
      currentViewer = urlType;
      return currentViewer;
    }

    Object.keys(ALL_VIEWER_TYPES).map(_viewerType => {
      const fileTypeList = ALL_VIEWER_TYPES[_viewerType];
      const isFound = fileTypeList.indexOf(extension) !== -1;
      if (isFound) {
        currentViewer = _viewerType;
      }
    });
    return currentViewer;
  };

  return {
    viewerType: getViewerType(),
    urlType,
    isUrl: !!urlType,
    extension
  };
};

export const useViewerType = (fileName: string, fileUrl: string) => {
  const result = computed(() => getViewerType(fileName, fileUrl));
  return {
    viewerType: result.value.viewerType,
    urlType: result.value.urlType,
    isUrl: result.value.isUrl,
    extension: result.value.extension
  };
};
