
import { defineComponent } from "vue";
import { ElImageViewer } from "element-plus";

export default defineComponent({
  props: {
    onShouldClose: Function,
    url: String,
    urlList: Array
  },
  components: {
    ElImageViewer
  }
});
