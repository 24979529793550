
import { defineComponent, onMounted, ref, onBeforeUnmount } from "vue";
export default defineComponent({
  props: {
    url: String,
    onReady: Function
  },
  components: {},
  setup(props) {
    const iframeRef = ref<null | HTMLIFrameElement>(null);
    const onLoadCallback = () => {
      props.onReady && props.onReady();
    };
    onMounted(() => {
      iframeRef.value?.addEventListener("load", onLoadCallback);
    });
    onBeforeUnmount(() => {
      iframeRef.value?.removeEventListener("load", onLoadCallback);
    });
    return { iframeRef };
  }
});
