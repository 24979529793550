import { renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_modal = _resolveComponent("base-modal")

  return (_openBlock(), _createBlock(_component_base_modal, {
    title: _ctx.title,
    size: "lg",
    id: "attachment-preview",
    "show-immediately": true,
    scrollable: true
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["title"]))
}