import ApiService from "@/core/services/ApiService";
import { Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions } from "../enums/StoreEnums";

@Module
export default class AuthModule extends VuexModule {
  @Action
  [Actions.INVOCATION__GET_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/app/invocation`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}