import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import to from "await-to-js";

const asyncRouteComponent = (
  importFunction: () => Promise<any>
) => async () => {
  store.dispatch(Actions.SHOW_PAGE_PLACEHOLDER, true);
  const [err, res] = await to(importFunction());
  store.dispatch(Actions.SHOW_PAGE_PLACEHOLDER, false);
  if (err) return Promise.reject(err);
  return Promise.resolve(res);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/app/dashboard"
  },
  {
    path: "/app",
    name: "app",
    redirect: "/app/dashboard",
    component: () => import("@/layout/NewLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "app.dashboard",
        component: asyncRouteComponent(() =>
          import("@/views/app/Dashboard.vue")
        )
      },
      {
        path: "invocation",
        name: "app.invocation",
        component: asyncRouteComponent(() =>
          import("@/views/app/invocation/index.vue")
        )
      },
      {
        path: "invocation/:id/edit",
        name: "app.invocation-edit",
        component: asyncRouteComponent(() =>
          import("@/views/app/invocation/edit-invocation.vue")
        )
      },
      {
        path: "invocation/:id/detail",
        name: "app.invocation-detail",
        component: asyncRouteComponent(() =>
          import("@/views/app/invocation/detail/index.vue"),
        ),
        children: [
          {
            path: "bill",
            name: "app.invocation-detail.bill",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-bill.vue"),
            )
          },
          {
            path: "payload",
            name: "app.invocation-detail.payload",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-payload.vue"),
            )
          },
          {
            path: "response",
            name: "app.invocation-detail.response",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-response.vue"),
            )
          },
          {
            path: "inbound",
            name: "app.invocation-detail.inbound",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-inbound.vue"),
            )
          },
          {
            path: "outbound",
            name: "app.invocation-detail.outbound",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-outbound.vue"),
            )
          },
          {
            path: "webhook",
            name: "app.invocation-detail.webhook",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-webhook.vue"),
            )
          },
          {
            path: "institution",
            name: "app.invocation-detail.institution",
            component: asyncRouteComponent(() =>
              import("@/views/app/invocation/detail/invocation-institution.vue"),
            )
          },
        ]
      },
      {
        path: "bill",
        name: "app.bill",
        component: asyncRouteComponent(() =>
          import("@/views/app/bill/index.vue")
        )
      },
      {
        path: "payment",
        name: "app.payment",
        component: asyncRouteComponent(() =>
          import("@/views/app/payment/index.vue")
        )
      },
      {
        path: "webhook",
        name: "app.webhook",
        component: asyncRouteComponent(() =>
          import("@/views/app/webhook/index.vue")
        )
      },
      {
        path: "account",
        name: "app.account",
        component: asyncRouteComponent(() =>
          import("@/views/app/account/index.vue")
        )
      },
      {
        path: "management/client",
        name: "app.management.client",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/client/index.vue")
        )
      },
      {
        path: "management/client/create",
        name: "app.management.client.create",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/client/create-client.vue")
        )
      },
      {
        path: "management/client/:id/show",
        name: "app.management.client.show",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/client/detail.vue")
        )
      },
      {
        path: "management/institution",
        name: "app.management.institution",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/institution/index.vue")
        )
      },
      {
        path: "management/institution/create",
        name: "app.management.institution.create",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/institution/create-institution.vue")
        )
      },
      {
        path: "management/account",
        name: "app.management.account",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/account/index.vue")
        )
      },
      {
        path: "management/account/create",
        name: "app.management.account.create",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/account/create-account.vue")
        )
      },
      {
        path: "management/user",
        name: "app.management.user",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/user/index.vue")
        )
      },
      {
        path: "management/user/create",
        name: "app.management.user.create",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/user/create-user.vue")
        )
      },
      {
        path: "management/gateway",
        name: "app.management.gateway",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/gateway/index.vue")
        )
      },
      {
        path: "management/gateway/create",
        name: "app.management.gateway.create",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/gateway/create-gateway.vue")
        )
      },
      {
        path: "management/gateway/:id/edit",
        name: "app.management.gateway.edit",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/gateway/edit-gateway.vue")
        )
      },
      {
        path: "management/user/:id/show",
        name: "app.management.user.show",
        component: asyncRouteComponent(() =>
          import("@/views/app/management/user/detail.vue")
        )
      },
      {
        path: "reconcile",
        name: "app.reconcile",
        component: asyncRouteComponent(() =>
          import("@/views/app/reconcile/index.vue")
        )
      },
      {
        path: "journal-entry",
        name: "app.journal-entry",
        component: asyncRouteComponent(() =>
          import("@/views/app/journal/index.vue")
        )
      },
      {
        path: "journal-entry/file",
        name: "app.journal-entry-file",
        component: asyncRouteComponent(() =>
          import("@/views/app/journal/list-file.vue")
        )
      },
      {
        path: "report/channel",
        name: "app.report.channel",
        component: asyncRouteComponent(() =>
          import("@/views/app/reports/index.vue")
        )
      },
      {
        path: "finance/overbooking",
        name: "app.finance.overbooking",
        component: asyncRouteComponent(() =>
          import("@/views/app/finance/overbooking.vue")
        )
      },
      {
        path: "finance/account/inquiry",
        name: "app.finance.account.inquiry",
        component: asyncRouteComponent(() =>
          import("@/views/app/finance/account.vue")
        )
      },
      {
        path: "channel",
        name: "app.channel",
        component: asyncRouteComponent(() =>
          import("@/views/app/channel/index.vue")
        )
      },
      {
        path: "channel/:id/edit",
        name: "app.channel-edit",
        component: asyncRouteComponent(() =>
          import("@/views/app/channel/edit-channel.vue")
        )
      },
      {
        path: "transfer/history",
        name: "app.transfer.history",
        component: asyncRouteComponent(() =>
          import("@/views/app/transfer/index.vue")
        )
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/system/auth/SignIn.vue")
  },
  {
    path: "/fuelcard5cimbniaga",
    name: "fuelcard5cimbniaga",
    component: () => import("@/views/system/public/Fuel.vue")
  },
  {
    path: "/attributions",
    name: "about.attributions",
    component: () => import("@/views/attributions.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/system/error/Error404.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/403",
    name: "403",
    component: () => import("@/views/system/error/Error403.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // const FORBIDDEN_PATH_FOR_LOGGED_IN_USER = ["/login", "/password-reset"];
  // const nextRouteRoleRules = (to.meta?.roles as string[]) || ["*"];
  // const nextRouteIsPublicUrl = to.meta.public;
  // const nextRouteIsLoginUrl = to.fullPath.indexOf("/login") !== -1;
  // const nextRouteIsForbiddenPath = FORBIDDEN_PATH_FOR_LOGGED_IN_USER.includes(
  //   to.path
  // );

  // const isLoggedIn = JwtService.getToken();
  // const userType = store.getters.userType.toLowerCase();
  // const isAfterLogout = !isLoggedIn && nextRouteIsForbiddenPath;
  // const isRedirectionFromExpiredToken =
  //   isAfterLogout && nextRouteIsLoginUrl && from.path === "/login";

  // if (isRedirectionFromExpiredToken) {
  //   return false;
  // }

  // if (nextRouteIsPublicUrl || isAfterLogout) {
  //   return next();
  // }

  // if (!isLoggedIn) {
  //   return next({ name: "login" });
  // }

  // const routePermissionChecker = roleRulesParser(nextRouteRoleRules);
  // const isGrantedUser = routePermissionChecker(userType);

  // const nextRouteIsPermitted = !nextRouteIsForbiddenPath && isGrantedUser;

  // if (!nextRouteIsPermitted) {
  //   return next({ name: "app.dashboard" });
  // }
  return next();
});

export default router;
