
import { defineComponent, computed } from "vue";
import queryString from "query-string";
import AspectRatioIframe from "./AspectRatioIframe.vue";

export default defineComponent({
  props: {
    url: String,
    height: {
      type: Number
    },
    aspectRatio: {
      type: String,
      default: "16:9"
    }
  },
  components: {
    AspectRatioIframe
  },
  setup(props) {
    const embedUrl = computed(() => {
      const parsed = queryString.parseUrl(props.url!);
      const { query } = parsed;
      const _embedUrl = `https://www.youtube.com/embed/${query.v}`;
      return _embedUrl;
    });
    return { embedUrl };
  }
});
