
import { showErrorPopup } from "@/core/helpers/common-helper";
import { downloadToBlobUrl } from "@/core/helpers/download";
import to from "await-to-js";
import { defineComponent, provide, ref } from "vue";
import AttachmentPreviewModal from "./AttachmentPreviewModal.vue";
import { AttachmentData, getViewerType } from "./compositions";

export default defineComponent({
  components: {
    AttachmentPreviewModal
  },
  setup() {
    const counter = ref(0);
    const currentAttachmentData = ref<null | AttachmentData>(null);
    const setCurrentAttachment = async (attachmentProps: AttachmentData) => {
      const { isUrl, viewerType, extension } = getViewerType(
        attachmentProps.fileName,
        attachmentProps.url
      );
      if (!isUrl && !extension) {
        showErrorPopup({
          message: "File tidak teridentifikasi (ambigious file type)"
        });
        return false;
      }
      if (!viewerType) {
        showErrorPopup({
          message: "File tidak teridentifikasi (undefined viewer type)"
        });
        return false;
      }
      if (!isUrl) {
        const [err] = await to(downloadToBlobUrl(attachmentProps.url));
        if (err) return false;
      }
      counter.value = counter.value + 1;
      currentAttachmentData.value = attachmentProps;
      return true
    };
    const removeCurrentAttachment = () => {
      counter.value = counter.value + 1;
      currentAttachmentData.value = null;
    };

    provide("currentAttachment", currentAttachmentData);
    provide("removeCurrentAttachment", removeCurrentAttachment);
    provide("setCurrentAttachment", setCurrentAttachment);
    return { currentAttachmentData, counter };
  }
});
