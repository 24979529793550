import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_attachment_preview_modal = _resolveComponent("attachment-preview-modal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.currentAttachmentData !== null)
      ? (_openBlock(), _createBlock(_component_attachment_preview_modal, {
          key: _ctx.counter,
          "file-name": _ctx.currentAttachmentData.fileName,
          url: _ctx.currentAttachmentData.url
        }, null, 8, ["file-name", "url"]))
      : _createCommentVNode("", true)
  ], 64))
}