import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  email: string;
  type: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  permissions: Array<string>;
  roles: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  permissions = [];
  roles = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get userType(): string {
    return this.user.type;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /**
   * Get user permissions
   * @returns array
   */
  get getPermissions(): Array<string> {
    return this.permissions;
  }

  /**
   * Get user roles
   * @returns array
   */
  get getRoles(): Array<string> {
    return this.roles;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = [];
    this.permissions = data.permissions;
    this.roles = data.roles;
    JwtService.saveToken("logged-in");
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.permissions = [];
    this.roles = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.AUTH__LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/sanctum/csrf-cookie`)
        .then(() => {
          ApiService.post(`/app/login`, credentials)
            .then(async () => {
              await this.context.dispatch(Actions.AUTH__ME);
              resolve();
            })
            .catch(({ response }) => {
              this.context.commit(Mutations.SET_ERROR, response.data.message);
              reject();
            });
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.AUTH__ME]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/app/me`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, {
            user: {
              name: data.data.user.name,
              email: data.data.user.email,
              type: data.data.roles[0]
            },
            permissions: data.data.permissions,
            roles: data.data.roles,
          });
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.AUTH__LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }
}
