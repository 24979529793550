import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Breadcrumb } from "@/store/modules/NewBreadcrumbsModule";
import { warn } from "vue";

/**
 * Sets current page breadcrumbs
 * @param {string} title Current page title name
 */
export const setCurrentPageTitle = (title: string): void => {
  // netralize new toolbar
  store.commit(Mutations.BREADCRUMB__SET_PAGE_TITLE, "");
  store.commit(Mutations.BREADCRUMB__SET_BREADCRUMB, []);

  // and set value for old toolbar
  store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
    title: title
  });
};

export const setNewPageBreadcrumbs = (
  pageTitle: string,
  breadcrumbs: Breadcrumb[]
): void => {
  store.commit(Mutations.BREADCRUMB__SET_PAGE_TITLE, pageTitle);
  store.commit(Mutations.BREADCRUMB__SET_BREADCRUMB, breadcrumbs);
};

/**
 * Sets current page breadcrumbs
 * @param {string} pageTitle Current page title
 * @param {Array<string>} breadcrumbs Current page breadcrumbs
 */
export const setCurrentPageBreadcrumbs = (
  pageTitle: string,
  breadcrumbs: Array<string>
): void => {
  warn("[Mumtaz] we will sunsetting old breadcrumb function");
  setNewPageBreadcrumbs(pageTitle, [
    ...breadcrumbs.map(childLinkName => ({ title: childLinkName })),
    { title: pageTitle }
  ]);
};
